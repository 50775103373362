import React from 'react'
import {Divider} from "@mui/material";

const Footer = (props) => {
    return (
        <footer className="s-footer">
            <div className="d-flex p-3">
                <div className="row justify-content-center">
                    <div className="col-md-3 col-sm-6 mt-2">
                        <div className="footer-col">
                            <h4 className="widget-title">About us</h4>
                            <p>
                                Our game is an private server game for the popular
                                multiplayer game
                            </p>
                            <p>
                                Top Conquer 2024
                            </p>
                        </div>
                    </div>
                    <div className="col-md-push-2 col-md-3 mt-2">
                        <div className="footer-col">
                            <h4 className="widget-title">Affiliates</h4>

                            <ul className="list-inline social-1">
                                <li>
                                    <a href="https://www.elitepvpers.com/">ElitePVPers</a> </li>
                                <li> <a href="https://www.xtremetop100.com/in.php?site=1132376040">Xtremetop100</a> </li>
                                
                            </ul>
                        </div>
                    </div>
                    <div className="col-md-push-3 col-md-3 col-sm-6 mt-2">
                        <div className="footer-col">
                            <h4 className="widget-title">Join our Community</h4>
                            <p>
                                Join our <a href="https://discord.gg/EcbRHtpsqR">Discord</a> Channel
                            </p>
                            <p>
                                Like our <a href="https://www.facebook.com/profile.php?id=61561609215678">Facebook</a> Page
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer
