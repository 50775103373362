import mastheadImg from '../assets/s1.jpg'
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

export const Masthead = ({ serverState }) => {

    const navigate = useNavigate();
    return (
        <Box style={{
            background: `url('${mastheadImg}')`,
            height: 750,
            position: 'relative'
        }}>
            <Box style={{
                position: 'absolute',
                color: "white",
                top: '40%',
                marginLeft: '45px'
            }}>
               
                <Box style={{
                    borderLeft: '1px solid',
                    paddingLeft: 30,
                    marginTop: 20
                }}>
                    <h1>Top Conquer Classic</h1>
                    <h5>4 main classes and hosted in Singapore</h5>
                    <h5>Newly open server, come and play with us!</h5>
                    <span>{serverState}</span>
                </Box>
                <Box style={{
                    marginTop: 10
                }}>
                    <Button variant="contained" style={{
                        backgroundColor: '#a13d2d'
                    }} onClick={() => navigate('/downloads')} size="large">DOWNLOAD</Button>
                  

                </Box>
               
            </Box>
        </Box>

    )

};