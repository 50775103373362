import conquerImage1 from '../assets/7.jpg';
import conquerImage2 from '../assets/8.jpg';

import { Grid, Box, Typography, Divider } from "@mui/material";
import { Container } from 'react-bootstrap';
import { SectionDivider } from './common/section-divider';

export const HomeCards = () => {
    return (
        <Container style={{
            marginTop: '10px',
            padding: '20px'
        }}>
            <h2 style={{ paddingTop: 50, textTransform: 'uppercase', fontWeight: 'bold', textAlign: 'center' }}>Introduction</h2>
            <SectionDivider />
            <Grid container spacing={4} p={5}>
                <Grid item lg={6} md={6} xs={12}>
                    <img src={conquerImage1} style={{
                        borderRadius: '10px',
                        maxWidth: '100%'
                    }} alt="Conquer Img1" />
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                    <Typography variant='h2' style={{ fontSize: '45px' }}>What does make us special?</Typography>
                    <Typography variant='h6' style={{ fontSize: '20px' }}>All features tested and working fine like it was at the old days of Conquer Online.</Typography>
                    <Typography variant='h6' style={{ fontSize: '15px', color: '#ddd' }}>Classic and pure interface</Typography>
                    <Typography variant='h6' style={{ fontSize: '15px', color: '#ddd' }}>The closest classic experience you will find</Typography>
                    <Typography variant='h6' style={{ fontSize: '15px', color: '#ddd' }}>Custom additions and keep the server as interesting</Typography>
                    <Typography variant='h6' style={{ fontSize: '15px', color: '#ddd' }}>Exact calculations of Attack/Defense</Typography>
                </Grid>
            </Grid>
            <SectionDivider />
            {/* <Divider variant="middle" style={{ margin: '20px' }} /> */}
            <Grid container spacing={4} p={5} >
                <Grid item lg={6} md={6} xs={12}>
                    <Typography variant='h2' style={{ fontSize: '45px' }}>Server features</Typography>
                    <Typography variant='h6' style={{ fontSize: '15px', color: '#ddd' }}>Max level 130, 1st Reborn, max +9, max -5</Typography>
                    <Typography variant='h6' style={{ fontSize: '15px', color: '#ddd' }}>4 main classes, taoist/trojan/warrior/archer</Typography>

                    <Typography variant='h6' style={{ fontSize: '15px', color: '#ddd' }}>Singapore Hosted</Typography>
                </Grid>
                <Grid item lg={6} md={6} xs={12}>
                    <img src={conquerImage2} style={{
                        borderRadius: '10px',
                        maxWidth: '100%'
                    }} alt="Conquer Img1" />
                </Grid>
            </Grid>
        </Container >
    )
}