import Container from "@mui/material/Container";
import {Divider} from "@mui/material";
import Box from "@mui/material/Box";

export const DiscordWidget = () => {
    return (
        <Box className="gray">
            <Container style={{textAlign: "center", paddingBottom: 50}}>
                <h2 style={{paddingTop: 50, textTransform: 'uppercase', fontWeight: 'bold'}}>Game Discord</h2>
                <Divider style={{
                    width: '30%',
                    margin: '0 auto',
                    marginTop: 20,
                    height: 5
                }
                }/>
                <iframe src="https://discord.com/widget?id=1260267290033786920&theme=dark" width="350" height="500" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>  </Container>
        </Box>
    );
}