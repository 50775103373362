import Box from "@mui/material/Box";
import {Col, Row} from "react-bootstrap";
import IconBreadcrumbs from "../custom-breadcrumb";

export const FeaturesPage = () => {
    return (
        <Box className={"mb-5"}>
            <IconBreadcrumbs name={"Features"}/>
            <Box className="container page-content">
                <Row className="mb-5">
                    <Col md="6">
                        <h2 className="text-center">Core Features</h2>
                        <Box>
                            <ul>
                                <li>Found main Classes and Classic Speels implemented</li>
                                <li>First Reborn, +9 ,-5 and level 130 max only</li>
                                <li>
                                   Gems available
                                    <ul>
                                        <li>DragonGem</li>
                                        <li>PhoenixGem</li>
                                        <li>RainbowGem</li>
                                        <li>FuryGem</li>
                                        <li>KylinGem</li>
                                        <li>MoonGem, VioletGem and TortoiseGem</li>
                                    </ul>
                                </li>
                                <li>
                                Classic Features and Classic events!
                                </li>
                                <li>Counter Clock Guild War</li>
                                <li>Twin City War, Bird Island War, Ape City War, Phoenix Castle War, Desert City War</li>
                                <li>Score Guld and Guild Death Match</li>
                                <li>Monthly Super Guild War [Cash Prize]</li>
                                <li>Terato Dragon, Snow Banshee bosses and more</li>
                              
                                <li>VIP6 system auto loot +1, dragonball, meteors, notification of bless item  </li>
                                <li>Classic Quest like Blue mouse and Ancient Devil</li>
                                <li>@vipmineores in mining to skip stones</li>
                                <li>
                                    VIP 6 has x2 Experience level
                                    <ul>
                                        <li>
                                            <strong>Promotes a fair and fun environment for everyone</strong>
                                        </li>
                                    </ul>
                                </li>

                                <li>VIP 5 players has effects in +1 drop and open the VIP Warehouse</li>
                            </ul>
                        </Box>
                    </Col>
                    <Col md="6">
                        <a href="https://topconquer.net/image/play.jpg"><img
                            src="https://topconquer.net/image/play.jpg"
                            className="img-fluid m-2 s-img"
                            alt="Core Features Image" /></a>

                        <a href="https://topconquer.net/images/3.jpg"><img
                            src="https://topconquer.net/images/3.jpg" className="img-fluid m-2 s-img"
                            alt="VIP command"/></a>
                    </Col>
                </Row>
                <Row className="mb-5">
                    <Box className="col-md-6">
                        <a href="https://topconquer.net/images/4.jpg"><img
                            src="https://topconquer.net/images/4.jpg" className="img-fluid m-2 s-img"
                            alt="Player Command"/></a>
                    </Box>
                    <Box className="col-md-6">
                        <h2 className="text-center">PvP Events &amp; Tournaments</h2>
                        <div>
                            <ul>
                                <li>
                                    PK Tournaments
                                    <ul>
                                        <li>LastmanStanding</li>
                                        <li>FiveNout</li>
                                        <li>PassTheBomb</li>
                                        <li>DeathMatch</li>
                                        <li>WeeklyPK</li>
                                        
                                        <li>Class PK</li>
                                    </ul>
                                </li>
                                <li>Arena</li>
                                <li>Guild DeathMatch</li>
                                <li>ScoreWar</li>
                              
                                <li>Main Guild War</li>
                             
                            </ul>
                        </div>
                    </Box>
                </Row>
                <Row>
                    <Col md={6}>
                        <h2 className="text-center">Events &amp; Quests</h2>
                        <div>
                            <ul>
                                <li>All Daily Quests</li>
                           
                                <li>Dis City</li>
                                <li>CP Castle</li>
                                <li>SnakeKing, CW lord and DBDevils</li>
                              
                                <li>Banshee, Nemesis, Terato Dragon, etc</li>
                            </ul>
                        </div>
                    </Col>
                    <Col md={6}>
                        <a href="https://topconquer.net/images/2.jpg"><img
                            src="https://topconquer.net/images/2.jpg" className="img-fluid m-2 s-img"
                            alt="Super Guild War"/></a>
                    </Col>
                </Row>
            </Box>
        </Box>
    );
}