import React, {Component} from 'react'
import {Container, Card, Row, Col} from 'react-bootstrap'
import axios from 'axios'
import config from '../../config.json'
import IconBreadcrumbs from "../custom-breadcrumb";
import Box from "@mui/material/Box";
import {CardActions, CardContent, CardMedia} from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

class Downloads extends Component {
    state = {
        mega: '#',
        googleDrive: '#',
    }
    apiEndPoint = config.apiEndPoint + 'info'

    async componentDidMount() {
        try {
            const result = await axios.get(this.apiEndPoint)
            if (result.status == 200) {
                const data = result.data.value
                this.setState({mega: data.mega, googleDrive: data.google})
            }
        } catch {
        }
    }

    render() {
        return (
            <Box>
                <IconBreadcrumbs name={"Download the game"}/>
                <Container>
                    <div className="padding-top-2x padding-bottom-5x mb-2">
                        <h3 className="text-center mb-30">Game Client Download</h3>
                        <div className="row padding-bottom-1x">
                            <div className="col-md-6 offset-md-3">
                                <p>You can download and install Top Conquer using the following download link. Once
                                    downloaded, simply install and run the game from the new desktop shortcut.</p>

                                <div className="text-center">
                                    <a className="market-button windows-button"
                                        href="https://topconquer.net/topconquer.rar"><span
                                            className="mb-subtitle"></span><span
                                                className="mb-title">Direct Download</span></a>
                                </div>
                                <div className="text-center">
                                    <a className="market-button windows-button"
                                        href="https://drive.google.com/file/d/1LpGqp0SGzJVg6Z3IPf1OkoB3Hm6kA0O_/view?usp=sharing"><span
                                            className="mb-subtitle"></span><span
                                                className="mb-title">GoogleDrive</span></a>
                                </div>
                                <div className="text-center">
                                    <a className="market-button windows-button"
                                        href="https://topconquer.net/topconquer.rar"><span
                                            className="mb-subtitle"></span><span
                                                className="mb-title">MediaFire</span></a>
                                </div>
                                <div className="text-center">
                                    <a className="market-button windows-button"
                                        href="https://topconquer.net/topconquer.rar"><span
                                            className="mb-subtitle"></span><span
                                                className="mb-title">Mega</span></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </Box>

        )
    }
}

export default Downloads
